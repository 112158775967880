<script lang="ts" setup>
import type {
  Flag,
  LinkParam,
  MovieInterface,
  Show,
  ShowGroup,
} from '#gql/default'

interface Props {
  uuid: string
  movie: MovieInterface & { hasInfos?: boolean; hasTrailers?: boolean }
  showGroups: ShowGroup[]
  flags?: Flag[]
  linkParams?: LinkParam[]
  playtimesLayout?: PlaytimesLayout
  showCinemaNames?: boolean
  showPlaytimesOnly?: boolean
}

const { movie, flags } = defineProps<Props>()

const { t } = useI18n()
const appConfig = useAppConfig()
const isTrailerModalVisible = ref(false)
const isInfoModalVisible = ref(false)

const { icons } = useDesign()

function handlePosterClick() {
  if (movie.hasTrailers) {
    isTrailerModalVisible.value = true
  }
}
/**
 * Maps the given array of shows by adding a new property `isHidden` to each show's `flags` array.
 * The `isHidden` property is set to `true` if the flag's id is included in the `props.flags` array,
 * and `false` otherwise. This will filter out flags, which are already displayed for the movie itself.
 *
 * @param {Show[]} shows - The array of shows to be mapped.
 * @return {Show[]} The mapped array of shows.
 */
function mapShows(shows: Show[]): Show[] {
  return shows.map((show) => ({
    ...show,
    flags: show.flags?.map((flag) => ({
      ...flag,
      isHidden: flags?.map((f) => f.id).includes(flag.id),
    })),
  }))
}

const posterImage = computed(() =>
  getImage(
    movie,
    'thumbnailImage',
    t('movie.posterImage', { movie: movie.title }),
  ),
)

defineOptions({
  name: 'ProgramMovie',
})
</script>

<template>
  <div
    class="w-full text-pretty py-6"
    :class="{
      'grid grid-cols-[auto,1fr] grid-rows-[auto,minmax(0,1fr)] items-start gap-6 ':
        !showPlaytimesOnly,
    }"
  >
    <div v-if="!showPlaytimesOnly" class="w-24 md:row-span-2 md:w-40">
      <MoviePosterImage
        :image-object="posterImage"
        :image-config="appConfig.images.moviePage.poster"
        :rounded="ROUNDED_TYPE.LG"
        :with-trailer-button="movie.hasTrailers"
        @click="handlePosterClick"
      />
    </div>
    <div v-if="!showPlaytimesOnly" class="space-y-1.5 self-end md:self-start">
      <div v-if="flags?.length" class="flex flex-wrap space-x-2 text-sm">
        <Badge v-for="{ name, id } in flags" :key="id" class="align-middle">
          {{ name }}
        </Badge>
      </div>
      <div
        class="text-dark dark:text-light space-x-2 text-xl font-medium leading-tight md:text-2xl"
        @click="isInfoModalVisible = movie.hasInfos"
      >
        <span
          :class="{ 'hover:cursor-pointer hover:underline': movie.hasInfos }"
          v-text="movie.title"
        />

        <KButton
          v-if="movie.hasInfos"
          :size="BUTTON_SIZE.SM"
          :theme="BUTTON_THEME.TERTIARY"
          :title="t('btn.movieInfos')"
          :icon="icons.INFO_OUTLINE"
          class="-translate-y-0.5 align-middle"
        />
      </div>

      <MovieFacts :movie />

      <div
        v-if="movie.shortDescription?.length || movie.additionalInfo?.length"
        class="dark:text-neutral-light-1 light:text-neutral-dark-1 hidden space-y-1 md:block"
      >
        {{ movie.shortDescription }}
        {{ movie.additionalInfo }}
      </div>
    </div>

    <div
      class="relative col-span-2 space-y-8 md:col-span-1 md:col-start-2 md:row-start-2"
    >
      <div
        v-for="showGroup in showGroups"
        :key="showGroup.uuid"
        class="space-y-2"
      >
        <div
          v-if="showCinemaNames"
          class="text-xl"
          v-text="showGroup.cinema.name"
        />

        <ProgramPlaytimes
          :shows="mapShows(showGroup.shows?.data!)"
          :playtimes-layout
          :link-params
        />
      </div>
    </div>

    <LazyYoutubeModal
      v-if="isTrailerModalVisible"
      :remote-video-id="movie.trailers[0].remoteVideoId!"
      :autoplay="true"
      :mute="false"
      :controls="true"
      @close="isTrailerModalVisible = false"
    />

    <LazyProgramMovieModal
      v-if="isInfoModalVisible"
      :uuid
      @close="isInfoModalVisible = false"
    />
  </div>
</template>

<i18n>
de:
  movie:
    posterImage: "Filmplakat von {movie}"
  btn:
    movieInfos: "Filminfos"
es:
  movie:
    posterImage: "Cartel de {movie}"
  btn:
    movieInfos: "Información de la película"
</i18n>
